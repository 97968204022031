
export const scrubContent = (content) =>  {
    if(!content) return content;

    const imagesDomain = process.env.REACT_APP_RECDAY_HOST_IMAGES_DOMAIN;
    const assetsDomains = process.env.REACT_APP_RECDAY_HOST_ASSETS_DOMAIN;
    if(!!imagesDomain){
        content = content.replace(/sharetribe.imgix.net/g, imagesDomain);
    }

    if(!!assetsDomains){
        content = content.replace(/sharetribe-assets.imgix.net/g, assetsDomains);
    }

    return content;
}